import { ExtendedPaymentToken } from "../src/session/types";

export class MockPaymentTokenResponse {
  private value: ExtendedPaymentToken = {
    payment_token_id: "random-payment-token-id",
    channel_code: "random-channel-code",
    status: "REQUIRES_ACTION",
    actions: []
  };

  getValue() {
    return this.value;
  }

  withRedirectAction() {
    this.value.actions.push({
      type: "REDIRECT_CUSTOMER",
      value: "https://random-redirect-url.com",
      descriptor: "Redirect your customer here!"
    });
    return this;
  }

  withPostOnServerAction() {
    this.value.actions.push({
      type: "POST_ON_SERVER",
      value: "https://random-redirect-url.com/payment_tokens/ptkn_123456",
      descriptor: "AUTH_URL"
    });
    return this;
  }

  withStatusRequiresAction() {
    this.value.status = "REQUIRES_ACTION";
    return this;
  }

  withStatusActive() {
    this.value.status = "ACTIVE";
    return this;
  }

  withStatusFailed() {
    this.value.status = "FAILED";
    return this;
  }

  withSessionTokenRequestId() {
    this.value.session_token_request_id = "random-session-token-request-id";
    return this;
  }
}
